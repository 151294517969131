import { graphql } from 'gatsby'
import { useState } from 'react'
import GatsbyHead from '../components/GatsbyHead'
import LayoutWrapper from '../components/LayoutWrapper'
import PageSectionBannerTextBackground from '../components/PageSectionBannerTextBackground'
import SampleExampleBanner from '../components/SampleExampleBanner'
import SampleExampleCopyContent from '../components/SampleExampleCopyContent'
import SampleExampleStickyButton from '../components/SampleExampleStickyButton'
import Section from '../components/Section'
import useSignUpLink from '../hooks/queries/useSignUpLink'
import { useIntl } from '../utils/IntlContext'
import { getLocalizedRootUrlPrefix } from '../utils/locales'

export const query = graphql`
  query ResumeSample($contentful_id: String!, $locale: String!) {
    contentfulResumeCategory(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      id
      contentful_id
      parentPage {
        slug
      }
      slug
      node_locale
      name: title
      h1Title
      metaTitle
      metaDescription
      how_to {
        childMarkdownRemark {
          timeToRead
          tableOfContents(maxDepth: 3)
          htmlAst
          headings {
            depth
          }
          images {
            contentful_id
            title
            localFile {
              childImageSharp {
                gatsbyImageData(width: 760)
              }
              publicURL
              extension
            }
          }
        }
      }
      introduction {
        childMarkdownRemark {
          html
        }
      }
      overview_heading
      overview {
        childMarkdownRemark {
          html
          htmlAst
          images {
            contentful_id
            title
            localFile {
              childImageSharp {
                gatsbyImageData(width: 760)
              }
              publicURL
              extension
            }
          }
        }
      }
      cvs {
        id
        slug
        title
        sample
        image {
          childImageSharp {
            gatsbyImageData(width: 800, quality: 50)
          }
        }
        thumbnail: image {
          childImageSharp {
            gatsbyImageData(width: 297)
          }
        }
      }
      childContentfulResumeCategoryHowToTextNode {
        id
        childMarkdownRemark {
          html
        }
      }
      childContentfulResumeCategoryOverviewTextNode {
        id
        childMarkdownRemark {
          html
        }
      }
    }
    resume_tips: allContentfulComponentResumeTips(
      filter: { contentful_id: { eq: "4BdgfNFpMO1sPz0PAdACAF" }, node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          ...ComponentResumeTips
        }
      }
    }
    shapesCvBanner: contentfulBannerTextBackground(
      contentful_id: { eq: "1Sh1B0aN8HLsiJlcYU4rkP" }
      node_locale: { eq: $locale }
    ) {
      ...BannerTextBackground
    }
  }
`

const generateMetaTitle = (metaTitle, name) => metaTitle || `${name} Resume Samples and Templates | VisualCV`

const generateMetaDescription = (metaDescription, name) =>
  metaDescription ||
  `Looking for ${name} resume samples? Industry leading samples, skills, & templates to help you create a job-winning professional resume.`

const ResumeSample = ({
  pageContext: { translatedPages },
  data,
  data: {
    contentfulResumeCategory: {
      parentPage,
      slug,
      name,
      h1Title,
      cvs,
      introduction,
      overview_heading,
      overview,
      how_to,
    },
    shapesCvBanner,
  },
}) => {
  cvs = cvs.filter((cv) => cv && cv.image)

  const [activeCvIndex, setActiveCVIndex] = useState(0)

  const { locale } = useIntl()

  const parentBasePath = getLocalizedRootUrlPrefix(parentPage, locale)

  const signupLink = useSignUpLink(locale)

  const displayTOC = how_to?.childMarkdownRemark?.headings.filter((heading) => heading.depth <= 3).length >= 3
  const displayStickyResume = displayTOC && how_to?.childMarkdownRemark?.timeToRead >= 2

  const resume_tips = data.resume_tips.edges.map((edge) => edge.node).find((node) => node.node_locale === locale)

  const sampleCta = cvs[activeCvIndex].sample
    ? {
        url: { app_url: true, url: `/?sample=${cvs[activeCvIndex].slug}` },
        text: { value: 'Use This Sample' },
        nofollow: true,
      }
    : signupLink

  const heading = {
    tagName: 'h2',
    type: 'element',
    children: [{ type: 'text', value: overview_heading || 'Overview' }],
  }

  const howTo = how_to
    ? {
        childMarkdownRemark: {
          htmlAst: {
            type: 'root',
            children: [
              overview ? heading : [],
              ...(overview?.childMarkdownRemark?.htmlAst?.children ?? []),
              ...(how_to?.childMarkdownRemark?.htmlAst?.children ?? []),
            ],
          },
          images: [...(overview?.childMarkdownRemark?.images ?? []), ...(how_to?.childMarkdownRemark?.images ?? [])],
        },
      }
    : null

  return (
    <LayoutWrapper translatedPages={translatedPages}>
      <SampleExampleBanner
        resumes={cvs}
        activeIndex={activeCvIndex}
        setActiveIndex={setActiveCVIndex}
        signupLink={signupLink}
        name={name}
        h1Title={h1Title}
        introduction={introduction?.childMarkdownRemark?.html}
        parentBasePath={parentBasePath}
        slug={slug}
        isResumeSample
      />
      <SampleExampleCopyContent
        howTo={howTo}
        resume_tips={resume_tips}
        signupLink={signupLink}
        tableOfContents={how_to?.childMarkdownRemark?.tableOfContents}
        displayTOC={displayTOC}
        displayStickyResume={displayStickyResume}
        isResumeSample
        highlightedResume={cvs[activeCvIndex]}
      />
      <Section
        align="center"
        hasDescription
        hasComponent>
        <PageSectionBannerTextBackground
          altCta={sampleCta}
          banner={shapesCvBanner}
        />
      </Section>
      <SampleExampleStickyButton
        highlightedResume={cvs[activeCvIndex]}
        signupLink={signupLink}
      />
    </LayoutWrapper>
  )
}

/** @type {import('gatsby').HeadFC} */
export const Head = ({
  pageContext: { canonicalUrl, translatedPages },
  data: {
    contentfulResumeCategory: { name, metaTitle, metaDescription },
  },
}) => {
  metaTitle = generateMetaTitle(metaTitle, name)
  metaDescription = generateMetaDescription(metaDescription, name)

  return (
    <GatsbyHead
      pageTitle={metaTitle}
      metaDescription={metaDescription}
      canonicalUrl={canonicalUrl}
      translatedPages={translatedPages}
    />
  )
}

export default ResumeSample
